.collapse {
	font-family: Roboto, sans-serif;

	&__title {
		display: flex;
		height: 45px;
		justify-content: space-between;
		align-items: center;
		font-size: 15px;
		line-height: 18px;
		cursor: pointer;

		&:hover {

			.collapse__chevron, .collapse__icon {
				background-color: var(--mave-accent-primary);
			}
		}
	}

	&__left-side {
		display: flex;
		overflow: hidden;
		align-items: center;
	}

	&__icon {
		margin-right: 12px;
		width: 20px;
		min-width: 20px;
		height: 20px;
		mask-repeat: no-repeat;
		mask-position: center;
		background-color: var(--mave-foreground-primary);
	}

	&__text {
		overflow: hidden;
		color: var(--mave-foreground-primary);

		& > div {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&__chevron {
		margin-left: 5px;
		width: 15px;
		min-width: 15px;
		height: 8px;
		mask-image: url('../img/icons/dropdown-down.svg');
		mask-repeat: no-repeat;
		background-color: var(--mave-foreground-secondary);
	}

	&__content {
		display: none;
	}

	&_opened {

		.collapse__content {
			display: block;
		}

		.collapse__chevron {
			mask-image: url('../img/icons/dropdown-up.svg');
			background-color: var(--mave-foreground-primary);
		}
	}
}
