.modal-owner {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--mave-overlay-medium);
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 24px;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
	background: var(--mave-background-primary-1);
	border-radius: 10px;
	transform: translate(-50%,-50%);

	&__header {
		position: relative;
		padding-bottom: 24px;
		font-size: 18px;
		line-height: 21px;
		text-align: center;
		color: var(--mave-foreground-primary);

		&-close {
			position: absolute;
			top: -2px;
			right: 0;
			width: 24px;
			height: 24px;
			background-color: var(--mave-foreground-secondary);
			cursor: pointer;
			mask-image: url('../img/icons/close.svg') ;
			mask-repeat: no-repeat;

			&:hover {
				background-color: var(--mave-foreground-primary);
			}
		}
	}

	&__body {
		overflow-y: scroll;
		max-height: calc(100vh - 160px);
		color: var(--mave-foreground-primary);

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
			background-color: transparent;
		}
	}

	&__bottom-buttons {
		display: flex;
		justify-content: space-between;

		.button {
			margin-bottom: 0;
		}

		.button + .button {
			margin-left: 12px;
		}
	}

	&_type {

		&_edit-panel {

			.start-page, .started-page {
				margin-top: 8px;
				display: inline-flex;
				align-items: center;
				font-size: 15px;
				line-height: 18px;
				color: var(--mave-foreground-primary);
				cursor: pointer;

				&__icon {
					margin-right: 10px;
					width: 20px;
					height: 20px;
					background-color: var(--mave-foreground-primary);
					mask-image: url('../img/icons/home.svg');
					mask-repeat: no-repeat;
					mask-position: center;
				}
			}

			.started-page {
				color: var(--mave-foreground-secondary);
				cursor: default;

				&__icon {
					width: 20px;
					height: 20px;
					background-color: var(--mave-foreground-secondary);
					mask-image: url('../img/icons/check.svg');
					mask-repeat: no-repeat;
					mask-position: center;
				}
			}
		}
	}
}

.modal_type {

	&_delete-app {

		&__sub-title {
			font-weight: 500;
		}
	}

	&_settings {

		.upload-image {
			margin-bottom: 24px;
			display: flex;

			&__title {
				margin-bottom: 8px;
				font-size: 16px;
				font-weight: 500;
				color: var(--mave-foreground-primary);
			}

			&__text {
				margin-bottom: 12px;
				font-size: 13px;
				color: var(--mave-foreground-primary);
			}

			&__image {
				margin-right: 16px;
				width: 139px;
				min-width: 139px;
				height: 139px;
				background-color: var(--mave-background-secondary);
				border-radius: 10px;

				&_large {
					background-image: url('../img/default-app-icon.png');
					background-repeat: no-repeat;
					background-size: contain;
					border: .5px solid var(--mave-border);
				}
			}

			&__inner_image {
				margin-top: 32px;
				margin-left: 32px;
				width: 75px;
				min-width: 75px;
				height: 75px;
				background-image: url('../img/default-app-icon.png');
				background-repeat: no-repeat;
				background-size: contain;
				border: .5px solid var(--mave-border);
				border-radius: 10px;
			}
		}

		.title {
			margin-bottom: 8px;
			font-size: 16px;
			font-weight: 500;
			color: var(--mave-foreground-primary);
		}

		.desc {
			margin-bottom: 12px;
			font-size: 13px;
			color: var(--mave-foreground-primary);
		}
	}

	&_error {

		.error-content {
			display: flex;
			align-items: center;

			&__icon {
				margin-right: 12px;
				width: 36px;
				min-width: 36px;
				height: 36px;
				background-color: var(--mave-warning);
				mask-image: url('../img/icons/error.svg') ;
				mask-repeat: no-repeat;
			}

			&__text {
				font-size: 15px;
				color: var(--mave-foreground-primary);
			}

			&_is-top {
				align-items: flex-start;
			}
		}
	}
}
