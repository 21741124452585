.header-menu-item {
	padding: 2px;
	display: inline-flex;
	align-items: center;
	font-family: Roboto, sans-serif;
	font-size: 15px;
	line-height: 18px;
	color: var(--mave-foreground-primary);
	cursor: pointer;

	&:hover {
		color: var(--mave-accent-primary);

		.header-menu-item__icon {
			background-color: var(--mave-accent-primary);
		}

		.header-menu-item__icon-more {
			background-color: var(--mave-accent-primary);
		}
	}

	&__icon {
		margin-right: 9.5px;
		width: 20px;
		height: 20px;
		background-color: var(--mave-foreground-primary);
		mask-repeat: no-repeat;
		mask-position: center;
	}

	&__icon-more {
		margin-left: 9.5px;
		width: 11px;
		height: 7px;
		background-color: var(--mave-foreground-secondary);
		mask-image: url('../img/icons/dropdown-mini-down.svg');
		mask-repeat: no-repeat;
		mask-size: contain;
	}
}
