.switch {
	margin-bottom: 16px;
	font-family: Roboto, sans-serif;

	&__container {
		display: flex;
		height: 45px;
		align-items: center;
		font-size: 15px;
		line-height: 18px;
		color: var(--mave-foreground-secondary-button);
		background: var(--mave-background-primary-2);
		border-radius: 10px;
	}

	&__icon {
		width: 22px;
		height: 22px;
		background-color: var(--mave-foreground-secondary);
		mask-repeat: no-repeat;
		mask-size: contain;
	}

	&__option {
		display: flex;
		width: 50%;
		justify-content: center;
		cursor: pointer;

		&:hover {
			color: var(--mave-foreground-primary);;

			.switch__icon {
				background-color: var(--mave-foreground-primary);
			}
		}

		&_selected {
			display: flex;
			height: calc(100% - 6px);
			justify-content: center;
			align-items: center;
			color: var(--mave-foreground-primary);
			background: var(--mave-background-primary-1);
			border-radius: 8px;

			.switch__icon {
				background-color: var(--mave-foreground-primary);
			}
		}
	}

	&__option:first-child {
		margin-left: 3px;
		padding-right: 3px;
	}

	&__option:last-child {
		margin-right: 3px;
		padding-left: 3px;
	}
}
