.dropdown {
	margin-bottom: 16px;
	font-family: Roboto, sans-serif;
	cursor: pointer;

	&__container {
		position: relative;
	}

	&__selected {
		padding-right: 15px;
		padding-left: 15px;
		display: flex;
		box-sizing: border-box;
		height: 45px;
		align-items: center;
		font-size: 15px;
		line-height: 18px;
		background: var(--mave-background-primary-2);
		border: 1px solid rgba(0, 0, 0, 0);
		border-radius: 10px;

		&:hover {

			.dropdown__selected-icon {
				background-color: var(--mave-accent-primary);
			}
		}

		&-text {
			overflow: hidden;
			width: calc(100% - 20px);
			white-space: nowrap;
			text-overflow: ellipsis;
			color: var(--mave-foreground-primary);

			&_light {
				color: var(--mave-foreground-secondary-button);
			}
		}

		&-icon {
			margin-left: 5px;
			width: 15px;
			height: 8px;
			background-color: var(--mave-foreground-secondary);
			mask-image: url('../img/icons/dropdown-down.svg');
			mask-repeat: no-repeat;
		}
	}

	&__options {
		position: absolute;
		z-index: 2;
		right: 0;
		left: 0;
		display: none;
		overflow: hidden;
		background-color: var(--mave-background-primary-1);
		border: 1px solid var(--mave-border);
		border-top: none;
		border-radius: 0 0 10px 10px;
	}

	&__option {
		padding-right: 15px;
		padding-left: 15px;
		display: flex;
		height: 45px;
		align-items: center;
		color: var(--mave-foreground-primary);

		&:hover {
			background-color: var(--mave-background-primary-2);
		}
	}

	&_opened {

		.dropdown__options {
			display: block;
		}

		.dropdown__selected {
			border: 1px solid rgba(0, 0, 0, .12);
			border-bottom-color: transparent;
			border-radius: 10px 10px 0 0;
		}

		.dropdown__selected-icon {
			background-color: var(--mave-foreground-primary);
			mask-image: url('../img/icons/dropdown-up.svg');
		}

		&.dropdown_is-top {

			.dropdown__options {
				bottom: 45px;
				border: 1px solid rgba(0, 0, 0, .12);
				border-bottom: none;
				border-radius: 10px 10px 0 0;
			}

			.dropdown__selected {
				border: 1px solid rgba(0, 0, 0, .12);
				border-top-color: transparent;
				border-radius: 0 0 10px 10px;
			}
		}
	}
}
