.page-login {
	display: flex;
	min-width: 100vw;
	min-height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Roboto, sans-serif;
	background-color: var(--mave-background-primary-2);

	&__logo {
		margin-bottom: 24px;
		width: 205px;
		height: 78px;
		background-image: url('../img/logo.svg');
		background-size: contain;
	}

	&__block {
		padding-top: 30px;
		padding-bottom: 30px;
		display: flex;
		width: 424px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: var(--mave-background-primary-1);
		border-radius: 10px;
		box-shadow: 0 6px 12px rgba(0,0,0,0.04);

		.button {
			margin-bottom: 0;
		}
	}

	&__title {
		margin-bottom: 26px;
		font-size: 18px;
		line-height: 21px;
		font-weight: 500;
	}
}
