.ios-switch {
	position: relative;
	margin-bottom: 16px;
	width: 64px;
	height: 34px;
	background-color: var(--mave-background-primary-2);
	border-radius: 32px;
	cursor: pointer;

	&:hover {
		background-color: var(--mave-background-secondary);

		.ios-switch__dot {
			background-color: var(--mave-background-primary-1);
		}

		&.ios-switch_is-on {

			.ios-switch__dot {
				background-color: var(--mave-accent-primary);
			}
		}
	}

	&__dot {
		position: absolute;
		top: 4px;
		left: 4px;
		width: 26px;
		height: 26px;
		background-color: var(--mave-foreground-secondary-button);
		border-radius: 26px;
		transition: left .15s ease-in-out, background-color .15s ease-in-out;
	}

	&_is-on {

		.ios-switch__dot {
			background-color: var(--mave-accent-primary);
			left: 34px;
		}
	}
}
