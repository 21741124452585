.component-settings-list {
	font-family: Roboto, sans-serif;

	&__name {
		margin-bottom: 20px;
		font-size: 15px;
		line-height: 18px;
		color: var(--mave-foreground-primary);
	}
}
