.page-loader {
	position: fixed;
	top: 0;
	display: flex;
	min-width: 100vw;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
	background-color: var(--mave-background-primary-2);

	.icon-loader {
		width: 56px;
		height: 56px;
		background-color: var(--mave-accent-primary);
		animation: 1.5s linear 0s infinite rotate;
		mask-image: url('../img/icons/load.svg');
		mask-repeat: no-repeat;
		mask-size: contain;
	}

	@keyframes rotate {

		from {
			transform: rotateZ(0deg);
		}

		to {
			transform: rotateZ(360deg);
		}
	}
}
