.page-editor {
	display: inline-block;
	min-width: 100%;
	min-height: 100vh;

	&__content {
		display: inline-flex;
		width: 100%;
		min-height: calc(100vh - 60px);
	}

	&__left-side, &__right-side {
		padding: 24px;
		box-sizing: border-box;
		width: 366px;
		min-width: 366px;
		background-color: var(--mave-background-primary-1);
	}

	&__middle {
		position: relative;
		padding-top: 76px;
		padding-bottom: 76px;
		display: flex;
		width: calc(100% - 366px - 366px);
		justify-content: center;
		background-color: var(--mave-background-primary-2);
	}
}
