.popup-menu {
	position: relative;
	font-family: Roboto, sans-serif;

	&_position_left {

		.popup-menu__items {
			left: 0;
		}
	}

	&_position_right {

		.popup-menu__items {
			right: 0;
		}
	}

	&_position_center {

		.popup-menu__items {
			left: calc(50%);
			transform: translateX(-50%);
		}
	}

	&_opened {

		.popup-menu__items {
			display: block;
		}
	}

	&__title {
		display: flex;
		box-sizing: border-box;
		height: 45px;
		align-items: center;
		font-size: 15px;
		line-height: 18px;
		cursor: pointer;

		&:hover {

			.popup-menu__text {
				color: var(--mave-accent-primary);
			}

			.popup-menu__icon, .popup-menu__chevron {
				background-color: var(--mave-accent-primary);
			}
		}
	}

	&__icon {
		margin-right: 9.5px;
		width: 20px;
		height: 20px;
		mask-repeat: no-repeat;
		mask-position: center;
		background-color: var(--mave-foreground-primary);
	}

	&__text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: var(--mave-foreground-primary);
	}

	&__chevron {
		margin-left: 5px;
		width: 15px;
		min-width: 15px;
		height: 8px;
		background-color: var(--mave-foreground-secondary);
		mask-image: url('../img/icons/dropdown-mini-down.svg');
		mask-repeat: no-repeat;
	}

	&__items {
		position: absolute;
		z-index: 3;
		padding: 12px 4px;
		display: none;
		overflow: hidden;
		box-sizing: border-box;
		background: var(--mave-background-primary-1);
		border-radius: 10px;
		box-shadow: 0 8px 24px var(--mave-elevation);
	}

	&__item {
		padding-right: 15px;
		padding-left: 15px;
		display: flex;
		align-items: center;
		font-size: 15px;
		line-height: 45px;
		color: var(--mave-foreground-primary);
		border-radius: 10px;
		cursor: pointer;

		&:hover {
			background: var(--mave-background-primary-2);
		}

		div {
			white-space: nowrap;
			color: var(--mave-foreground-primary);
		}
	}

}
