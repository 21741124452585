.page-selector {
	font-family: Roboto, sans-serif;

	&__option {
		margin-right: -15px;
		margin-left: -15px;
		padding-right: 15px;
		padding-left: 15px;
		display: flex;
		width: 100%;
		align-items: center;

		&:hover {

			.page-selector__option-icon {
				display: block;
			}

			.page-selector__option-text {

			}
		}

		&-text {
			overflow: hidden;
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&-icon {
			margin-right: -10px;
			padding: 10px;
			display: none;

			&:hover {

				.page-selector__option-icon-inner {
					background-color: var(--mave-foreground-primary);
				}
			}

			&-inner {
				width: 15px;
				min-width: 15px;
				height: 15px;
				background-color: var(--mave-foreground-secondary);
				mask-image: url('../img/icons/edit.svg');
				mask-repeat: no-repeat;
			}
		}
	}

	&__option-add-page {
		display: flex;
		align-items: center;
	}

	&__add-page-icon {
		margin-right: 14px;
		width: 15px;
		height: 15px;
		mask-image: url('../img/icons/add.svg');
		mask-repeat: no-repeat;
		background-color: var(--mave-foreground-primary);
	}
}
