.input, .textarea {
	margin-bottom: 16px;
	padding: 12.5px 15px;
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	font-family: Roboto, sans-serif;
	font-size: 15px;
	line-height: 18px;
	white-space: nowrap;
	color: var(--mave-foreground-primary);
	background: var(--mave-background-primary-2);
	border: .5px solid var(--mave-border);
	border-radius: 10px;

	&:focus {
		border-color: var(--mave-accent-primary);
		outline: none;
	}

	&-error {
		margin-top: -12px;
		margin-bottom: 16px;
		font-size: 10px;
		color: var(--mave-warning);
	}

	&_error {
		border-color: var(--mave-warning);
	}
}

.textarea {
	font-family: Roboto, sans-serif;
	white-space: normal;
	resize: none;
}

.rc-input-number.custom {
	position: relative;
	margin-bottom: 16px;
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	height: 45px;
	font-family: Roboto, sans-serif;
	border: .5px solid var(--mave-border);
	border-radius: 10px;
	box-shadow: none;
	transition: none;

	&:hover, &.rc-input-number-focused {
		border-color: var(--mave-border);
		box-shadow: none;

		.rc-input-number-handler-wrap {
			display: flex;
		}
	}

	&:hover, &.rc-input-number-focused {
		border-color: var(--mave-border);
		box-shadow: none;

		.rc-input-number-handler-wrap {
			display: flex;
		}
	}

	&.rc-input-number-focused {
		border-color: var(--mave-accent-primary);
	}

	&.is-disabled:hover, &.is-disabled.rc-input-number-focused {

		.rc-input-number-handler-wrap {
			display: none;
		}

		.rc-input-number-input {
			cursor: auto;
		}
	}

	.rc-input-number-handler-wrap {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		display: none;
		width: auto;
		flex-direction: column;
		border: none;
	}

	.rc-input-number-handler-up, .rc-input-number-handler-down {
		padding: 0 13px 0 9px;
		display: block;
		height: 50%;
		background: transparent;
		border: none;
		cursor: pointer;
	}

	.rc-input-number-up-arrow, .rc-input-number-down-arrow {
		width: 13px;
		height: 100%;
		mask-repeat: no-repeat;
		mask-position: center;
	}

	.rc-input-number-handler-up {

		&:hover {

			.rc-input-number-up-arrow {
				background-color: var(--mave-foreground-secondary);
				mask-image: url('../img/icons/dropdown-mini-up.svg');
			}
		}
	}

	.rc-input-number-handler-down {

		&:hover {

			.rc-input-number-down-arrow {
				background-color: var(--mave-foreground-secondary);
				mask-image: url('../img/icons/dropdown-mini-down.svg');
			}
		}
	}

	.rc-input-number-up-arrow {
		background-color: var(--mave-border);
		mask-image: url('../img/icons/dropdown-mini-up.svg');
	}

	.rc-input-number-down-arrow {
		background-color: var(--mave-border);
		mask-image: url('../img/icons/dropdown-mini-down.svg');
	}

	.rc-input-number-input {
		padding: 12.5px 35px 12.5px 12.5px;
		box-sizing: border-box;
		width: 100%;
		font-size: 15px;
		line-height: 18px;
		text-align: left;
		color: var(--mave-foreground-primary);
		background: var(--mave-background-primary-2);
		transition: none;
	}
}

.input-size {
	position: relative;
	font-family: Roboto, sans-serif;

	&_auto {

		.rc-input-number-input {
			padding-left: 50px;
		}
	}

	&__select {
		position: absolute;
		z-index: 1;
		top: 10px;
		left: 10px;
		padding: 5px;
		font-size: 13px;
		line-height: 15px;
		text-align: center;
		color: var(--mave-foreground-secondary-button);
		cursor: pointer;
	}

	.rc-input-number.custom {

		.rc-input-number-input {
			padding: 12.5px 35px;
		}
	}
}

.input-color {
	position: relative;
	font-family: Roboto, sans-serif;

	&__input {
		padding-left: 41px;
		color: var(--mave-foreground-primary);
		cursor: pointer;
	}


	&__color {
		position: absolute;
		top: 13.5px;
		left: 15px;
		box-sizing: border-box;
		width: 18px;
		height: 18px;
		pointer-events: none;
		border: .5px solid var(--mave-border);
		border-radius: 4px;
	}

	&__picker {
		position: absolute;
		z-index: 2;
		top: calc(45px + 8px);

		.chrome-picker {
			padding: 10px;
			border-radius: 10px !important;
			box-shadow: 0 8px 24px var(--mave-elevation) !important;

			input {
				padding: 12.5px 15px;
				overflow: hidden;
				box-sizing: border-box;
				width: 100%;
				font-size: 15px;
				line-height: 18px;
				white-space: nowrap;
				background: var(--mave-background-primary-2);
				border: .5px solid var(--mave-border);
				border-radius: 6px !important;

				&:focus {
					border-color: var(--mave-accent-primary);
					outline: none;
				}
			}

			& > div:last-child {

				& > div:last-child {

					& > div:last-child {

						& > div {
							position: relative;

							&:after {
								position: absolute;
								top: 0;
								right: 0;
								width: 26px;
								height: 26px;
								content: '';
								mask-image: url('../img/icons/dropdown-mini-up-down.svg');
								mask-repeat: no-repeat;
								mask-position: center;
								mask-size: 13px;
								background-color: var(--mave-foreground-secondary);
							}

							&:hover {

								&:after {
									mask-image: url('../img/icons/dropdown-mini-up-down.svg');
									background-color: var(--mave-foreground-primary);
								}
							}

							& svg {

								& path {
									opacity: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	&_is-top {

		.input-color__picker {
			top: auto;
			bottom: calc(45px + 8px);
		}
	}

	& * {
		font-family: Roboto, sans-serif;
	}
}

.input-image {
	margin-bottom: 16px;

	&__input {
		display: none;
	}

	&__preview {
		position: relative;
		margin-top: 12px;
		display: inline-block;
	}

	&__image {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 10px;
	}

	&__icon-clear {
		position: absolute;
		top: 6px;
		right: 6px;
		width: 16px;
		height: 16px;
		background-image: url('../img/icons/close-square-normal.svg');
		background-repeat: no-repeat;
		background-size: contain;
		cursor: pointer;

		&:hover {
			background-image: url('../img/icons/close-square-hover.svg');
		}
	}

	&__bottom {
		margin-top: 12px;
		font-size: 14px;
		line-height: 15px;
		color: var(--mave-foreground-primary);
	}
}
