.components-list {

	&__item {
		display: flex;
		height: 45px;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		&:hover {

			.components-list__chevron, .components-list__left-icon {
				background-color: var(--mave-accent-primary);
			}
		}
	}

	&__left-side {
		display: flex;
		align-items: center;
	}

	&__left-icon {
		margin-right: 12px;
		width: 20px;
		min-width: 20px;
		height: 20px;
		mask-repeat: no-repeat;
		mask-position: center;
		background-color: var(--mave-foreground-primary);
	}

	&__left-title {
		font-size: 15px;
		line-height: 18px;
		color: var(--mave-foreground-primary);
	}

	&__chevron {
		margin-left: 5px;
		width: 8px;
		min-width: 8px;
		height: 15px;
		mask-image: url('../img/icons/goto.svg');
		mask-repeat: no-repeat;
		background-color: var(--mave-foreground-primary);
	}
}
