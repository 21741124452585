@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

body {
	margin: 0;
	padding: 0;
	overflow-x: auto !important;
	background-color: var(--mave-background-primary-2);
	min-width: 1150px;

	&.dragging {
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}
}

.Panel:after {
	position: absolute;
}
