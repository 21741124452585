.header-menu {
	padding-right: 24px;
	padding-left: 24px;
	display: flex;
	box-sizing: border-box;
	width: 100%;
	height: 60px;
	align-items: center;
	font-family: Roboto, sans-serif;
	background: var(--mave-background-primary-1);
	border-bottom: .5px solid var(--mave-border);

	&__left {
		width: 35%;
	}

	&__center {
		position: relative;
		display: flex;
		width: 30%;
		justify-content: center;

		.popup-menu {
			max-width: 100%;
		}

		.popup-menu__title {
			display: inline-flex;
			width: 100%;
			justify-content: center;
		}

		.popup-menu__text {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	&__right {
		display: flex;
		width: 35%;
		justify-content: flex-end;

		& > div {
			margin-left: 24px;
		}

		& > div:first-child {
			margin-left: 0;
		}

		.menu-with-switch {
			display: flex;
			align-items: center;

			&__title {
				margin-right: 56px;
			}

			.ios-switch {
				margin-bottom: 0;
			}
		}

		.popup-menu__item {

			&:hover {

				.ios-switch {
					background-color: var(--mave-background-primary-1);

					&:hover {
						background-color: var(--mave-background-primary-1);

						.ios-switch__dot {
							background-color: var(--mave-foreground-secondary-button);
						}
					}
				}
			}
		}
	}
}
