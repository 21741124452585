.draggable-component {
	margin-bottom: 12px;
	padding: 12px;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
	background-color: var(--mave-background-primary-2);
	border-radius: 10px;
	cursor: move;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	user-select: none;

	&:active {
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}

	&:hover {

		.draggable-component__title {
			color: var(--mave-accent-primary);
		}

		.draggable-component__image {
			border-color: var(--mave-accent-primary);
		}
	}

	&__title {
		padding-bottom: 12px;
		font-size: 13px;
		line-height: 15px;
		pointer-events: none;
		color: var(--mave-foreground-primary);
	}

	&__image {
		overflow: hidden;
		pointer-events: none;
		border: 1px solid transparent;
		border-radius: 5px;

		& > img {
			display: flex;
			width: 100%;
			pointer-events: none;
		}
	}

	&.is-drag {
		position: absolute;
		z-index: 2;
		//margin-right: -24px;
		//margin-left: -24px;
		//padding-right: 24px;
		//padding-left: 24px;
		width: 318px;
		//background-color: var(--mave-background-primary-1);
		//border: .5px solid var(--mave-border);
		border-radius: 10px;
		box-shadow: 0 8px 24px var(--mave-elevation);
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;

		&:hover {

			.draggable-component__title {
				color: var(--mave-foreground-primary);
			}

			.draggable-component__image {
				border-color: transparent;
			}
		}
	}
}
