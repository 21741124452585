.head {
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	font-family: Roboto, sans-serif;

	&__icon {
		margin-right: 17px;
		width: 9px;
		min-width: 9px;
		height: 15px;
		mask-repeat: no-repeat;
		background-color: var(--mave-foreground-primary);
	}

	&__title {
		font-size: 18px;
		line-height: 21px;
		font-weight: 500;
		color: var(--mave-foreground-primary);
	}

	&_clickable {
		cursor: pointer;

		&:hover {

			.head__icon {
				background-color: var(--mave-accent-primary);
			}
		}
	}

	&_is-padding-top {
		padding-top: 16px;
		padding-bottom: 8px;
	}
}
