.page-my-apps {
	font-family: Roboto, sans-serif;

	&__title {
		margin-top: 24px;
		margin-bottom: 24px;
		font-size: 18px;
		line-height: 21px;
		font-weight: 500;
		color: var(--mave-foreground-primary);
	}

	&__inner {
		margin-right: auto;
		margin-left: auto;
		width: calc(517px + 517px + 24px);
	}
}
