.empty-block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	&__icon {
		margin-bottom: 24px;
		width: 48px;
		height: 48px;
		mask-repeat: no-repeat;
		background-color: var(--mave-foreground-primary);
	}

	&__title {
		font-size: 18px;
		line-height: 21px;
		font-weight: 500;
		color: var(--mave-foreground-primary);
	}

	&__desc {
		margin-top: 12px;
		font-size: 13px;
		line-height: 15px;
		color: var(--mave-foreground-secondary)
	}
}
