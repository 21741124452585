.dragging-zone {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	background-color: var(--mave-background-secondary);
	border: 1.5px dashed var(--mave-accent-primary);

	&_drag-over {
		background-color: var(--mave-background-secondary);
		border: 1.5px dashed var(--mave-accent-primary);
	}

	&_is-hide {
		display: none;
	}

	&_is-full {
		position: absolute;
		top: 0;
		bottom: 0;
	}
}
