.constructor-component {
	position: relative;
	cursor: pointer;

	&:hover, &.hover {

		.constructor-component__control-left {
			display: flex;
		}

		.constructor-component__control-right {
			display: flex;
		}

		.constructor-component__component {
			opacity: .8;

			&.is-selected {
				opacity: 1;
			}
		}

		.constructor-component__border {
			border: 1px dashed var(--mave-border);
		}
	}

	&__component {
		position: relative;

		&_is-invisible {
			opacity: .5;
		}

		& > * {
			pointer-events: none;
		}
	}

	&__control-left {
		position: absolute;
		z-index: 11;
		top: 5px;
		left: 5px;
		display: none;

		& > div + div {
			margin-left: 5px;
		}
	}

	&__delete, &__copy, &__preview {
		display: flex;
		box-sizing: border-box;
		width: 30px;
		height: 30px;
		justify-content: center;
		align-items: center;
		background-color: var(--mave-background-primary-1);
		border: .5px solid var(--mave-border);
		border-radius: 4px;

		&:hover > div {
			background-color: var(--mave-accent-primary);
		}

		& > div {
			width: 16px;
			height: 16px;
			background-color: var(--mave-foreground-primary);
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 16px;
		}
	}

	&__delete {

		& > div {
			mask-image: url('../img/icons/delete.svg');
		}
	}

	&__copy {

		& > div {
			mask-image: url('../img/icons/copy.svg');
		}
	}

	&__preview {

		& > div {
			mask-image: url('../img/icons/preview.svg');
		}

		&_is-invisible {

			& > div {
				mask-image: url('../img/icons/unpreview.svg');
			}
		}
	}

	&__control-right {
		position: absolute;
		z-index: 11;
		top: 5px;
		right: 5px;
		display: none;
		box-sizing: border-box;
		width: 61px;
		height: 30px;
		align-items: center;
		background: var(--mave-background-primary-1);
		border: .5px solid var(--mave-border);
		border-radius: 4px;
	}

	&__up {
		display: flex;
		width: 30px;
		height: 30px;
		justify-content: center;
		align-items: center;

		&:hover > div {
			background-color: var(--mave-accent-primary);
		}

		& > div {
			width: 16px;
			height: 16px;
			background-color: var(--mave-foreground-primary);
			mask-image: url('../img/icons/dropdown-up.svg');
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 16px;
		}
	}

	&__down {
		display: flex;
		width: 30px;
		height: 30px;
		justify-content: center;
		align-items: center;

		&:hover > div {
			background-color: var(--mave-accent-primary);
		}

		& > div {
			width: 16px;
			height: 16px;
			background-color: var(--mave-foreground-primary);
			mask-image: url('../img/icons/dropdown-down.svg');
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 16px;
		}
	}

	&__mid {
		width: 1px;
		height: 22px;
		background-color: var(--mave-border);
	}

	&__border {
		position: absolute;
		z-index: 10;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
	}

	&_is-selected {

		.constructor-component__border {
			border: 1px dashed var(--mave-accent-primary) !important;
		}
	}
}
