.tab {

	&__headers {
		margin-bottom: 24px;
		display: flex;
	}

	&__header {
		position: relative;
		margin-bottom: 4px;
		padding: 4px 4px 10px;
		font-size: 15px;
		line-height: 18px;
		color: var(--mave-foreground-primary);
		cursor: pointer;

		& + & {
			margin-left: 24px;
		}

		&_selected {

			&:after {
				position: absolute;
				right: 4px;
				bottom: 0;
				left: 4px;
				height: 2px;
				content: '';
				background-color: var(--mave-accent-primary);
			}
		}
	}

	&__body {
		display: none;

		&_selected {
			display: block;
		}
	}
}
