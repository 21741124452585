.app-default-buttons {
	position: absolute;
	z-index: 2;
	top: 10px;
	right: 8px;
	width: 88px;
	height: 32px;
	pointer-events: none;
	background-image: url('../img/icons/app-default-buttons.svg');
	background-repeat: no-repeat;
	background-size: contain;
}
