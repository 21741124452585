:root, [ scheme = 'bright_light' ] .constructor-component {
	--mave-background-primary-1: #ffffff;
	--mave-background-primary-2: #f6f7f9;
	--mave-background-secondary: #ebf3ff;
	--mave-background-secondary-hover: #cde1ff;
	--mave-background-secondary-active: #d9e8ff;
	--mave-accent-primary: #0066ff;
	--mave-accent-primary-hover: #0066dd;
	--mave-accent-primary-active: #1177ff;
	--mave-accent-primary-disabled: #7db5ff;
	--mave-foreground-primary: #000000;
	--mave-foreground-secondary: #9299a3;
	--mave-foreground-secondary-button: #96989e;
	--mave-warning: #e64646;
	--mave-warning-hover: #ce2828;
	--mave-warning-active: #ff4444;
	--mave-warning-disabled: #ff8181;
	--mave-confirm: #4bb34b;
	--mave-confirm-hover: #349d34;
	--mave-confirm-active: #4bc94b;
	--mave-confirm-disabled: #8fce91;
	--mave-border: rgba(0, 0, 0, .12);
	--mave-overlay-medium: rgba(0, 0, 0, .3);
	--mave-elevation: rgba(0, 0, 0, .12);
}

[ constructor-theme = 'dark' ], [ scheme = 'space_gray' ] .constructor-component {
	--mave-background-primary-1: #19191a;
	--mave-background-primary-2: #282828;
	--mave-background-secondary: #313131;
	--mave-background-secondary-hover: #1f1f1f;
	--mave-background-secondary-active: #313131;
	--mave-accent-primary: #f9f9f9;
	--mave-accent-primary-hover: #e7e7e7;
	--mave-accent-primary-active: #ffffff;
	--mave-accent-primary-disabled: #a5a5a5;
	--mave-foreground-primary: #dcdcdc;
	--mave-foreground-secondary: #8d8d93;
	--mave-foreground-secondary-button: #6d6d6d;
	--mave-warning: #e64646;
	--mave-warning-hover: #ce2828;
	--mave-warning-active: #ff4444;
	--mave-warning-disabled: #9a3a3a;
	--mave-confirm: #4bb34b;
	--mave-confirm-hover: #349d34;
	--mave-confirm-active: #4bc94b;
	--mave-confirm-disabled: #3d7b3d;
	--mave-border: rgba(255, 255, 255, .12);
	--mave-overlay-medium: rgba(0, 0, 0, .4);
	--mave-elevation: rgba(0, 0, 0, .08);
}
