.button {
	margin-bottom: 16px;
	padding: 0 24px;
	display: inline-flex;
	height: 45px;
	align-items: center;
	font-family: Roboto, sans-serif;
	font-size: 15px;
	line-height: 18px;
	color: var(--mave-background-primary-1);
	border-radius: 10px;
	cursor: pointer;

	&__icon {
		margin-right: 8px;
		width: 20px;
		height: 20px;
		background-color: var(--mave-background-primary-1);
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;
	}

	&_primary {
		background-color: var(--mave-accent-primary);

		&:hover {
			background-color: var(--mave-accent-primary-hover);
		}

		&:active {
			background-color: var(--mave-accent-primary-active);
		}

		.button_disabled {
			background-color: var(--mave-accent-primary-disabled);
		}
	}

	&_secondary {
		color: var(--mave-accent-primary);
		background-color: var(--mave-background-secondary);

		&:hover {
			background-color: var(--mave-background-secondary-hover);
		}

		&:active {
			background-color: var(--mave-background-secondary-active);
		}

		.button__icon {
			background-color: var(--mave-accent-primary);
		}

		&.button_disabled {
			color: var(--mave-accent-primary-disabled);

			.button__icon {
				background-color: var(--mave-accent-primary-disabled);
			}
		}
	}

	&_gray {
		color: var(--mave-foreground-primary);
		background-color: var(--mave-background-primary-2);

		&:hover {
			color: var(--mave-accent-primary-hover);

			.button__icon {
				background-color: var(--mave-accent-primary-hover);
			}
		}

		&:active {
			color: var(--mave-accent-primary-active);

			.button__icon {
				background-color: var(--mave-accent-primary-active);
			}
		}

		.button__icon {
			background-color: var(--mave-foreground-primary);
		}

		.button_disabled {
			color: var(--mave-accent-primary-disabled);

			.button__icon {
				background-color: var(--mave-accent-primary-disabled);
			}
		}
	}

	&_danger {
		background-color: var(--mave-warning);

		&:hover {
			background-color: var(--mave-warning-hover);
		}

		&:active {
			background-color: var(--mave-warning-active);
		}

		&.button_disabled {
			background-color: var(--mave-warning-disabled);
		}
	}

	&_success {
		background-color: var(--mave-confirm);

		&:hover {
			background-color: var(--mave-confirm-hover);
		}

		&:active {
			background-color: var(--mave-confirm-active);
		}

		&.button_disabled {
			background-color: var(--mave-confirm-disabled);
		}
	}

	&_left {
		margin-right: 2px;
		border-radius: 10px 0 0 10px;
	}

	&_right {
		border-radius: 0 10px 10px 0;
	}

	&_middle {
		margin-right: 2px;
		border-radius: 0;
	}

	&.button_disabled {
		cursor: default;
	}

	&.button_icon-only {
		padding: 0;
		width: 45px;
		justify-content: center;

		.button__icon {
			margin-right: 0;
		}
	}
}
