.properties {
	margin: 0 -8px;
	display: flex;
	flex-wrap: wrap;

	&_top-margin {
		margin-top: 4.5px;
	}

	.property {
		margin: 0 8px;

		@for $k from 1 through 25 {
			@for $i from 1 through 25 {
				&.size-#{$k}-#{$i} {
					width: calc(#{$k * 100% / $i} - 8px * 2);
				}
			}
		}

		&.size-min {

			.mini-head {
				width: 0;
				white-space: nowrap;
			}
		}
	}
}

.collapse {

	.properties {
		margin-top: 8px;
	}
}
