.constructor-viewer {
	position: relative;

	&:hover {

		.app-default-buttons {
			display: none;
		}
	}
}
