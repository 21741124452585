.tapable-button {
	margin-bottom: 16px;
	padding: 0 16px;
	display: inline-flex;
	height: 45px;
	align-items: center;
	font-family: Roboto, sans-serif;
	font-size: 15px;
	line-height: 18px;
	color: var(--mave-foreground-primary);
	background-color: var(--mave-background-primary-2);
	border-radius: 10px;
	cursor: pointer;

	&:hover {
		color: var(--mave-accent-primary);

		.tapable-button__icon {
			background-color: var(--mave-accent-primary);
		}
	}

	&__icon {
		margin-right: 8px;
		width: 20px;
		height: 20px;
		background-color: var(--mave-foreground-primary);
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;
	}

	&_taped {
		color: var(--mave-background-primary-1);
		background-color: var(--mave-accent-primary);

		&:hover {
			color: var(--mave-background-primary-1);
			background-color: var(--mave-accent-primary-hover);

			.tapable-button__icon {
				background-color: var(--mave-background-primary-1);
			}
		}

		.tapable-button__icon {
			background-color: var(--mave-background-primary-1);
		}
	}

	&_only-icon {
		padding: 0;
		width: 45px;
		justify-content: center;

		.tapable-button__icon {
			margin-right: 0;
		}
	}

	&_left {
		margin-right: 2px;
		border-radius: 10px 0 0 10px;
	}

	&_right {
		border-radius: 0 10px 10px 0;
	}

	&_middle {
		margin-right: 2px;
		border-radius: 0;
	}
}
