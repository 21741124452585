.my-apps-list {
	margin-bottom: 48px;
	display: flex;
	flex-wrap: wrap;

	.my-app {
		margin-bottom: 24px;
		padding: 24px;
		display: flex;
		box-sizing: border-box;
		width: 517px;
		flex-direction: column;
		align-items: center;
		background-color: var(--mave-background-primary-1);
		border: 1px solid var(--mave-background-primary-1);
		border-radius: 10px;
		cursor: pointer;

		&:hover {
			border-color: var(--mave-accent-primary);
		}

		&:nth-child( 2n - 1 ) {
			margin-right: 24px;
		}

		&__logo {
			margin-bottom: 12px;
			box-sizing: border-box;
			width: 96px;
			height: 96px;
			background-image: url('../img/default-app-icon.png');
			background-repeat: no-repeat;
			background-size: contain;
			border: .5px solid var(--mave-border);
			border-radius: 8px;
		}

		&__title {
			margin-bottom: 6px;
			overflow: hidden;
			max-width: 100%;
			font-size: 18px;
			line-height: 21px;
			font-weight: 500;
			white-space: nowrap;
			text-overflow: ellipsis;
			color: var(--mave-foreground-primary);
		}

		&__users {
			margin-bottom: 14px;
			font-size: 13px;
			line-height: 15px;
			color: var(--mave-foreground-secondary);
		}

		&__control {
			display: flex;
		}

		&__action {
			width: 24px;
			height: 24px;
			background-color: var(--mave-foreground-secondary);
			cursor: pointer;
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 20px;

			&:hover {
				background-color: var(--mave-foreground-primary);
			}

			&_copy {
				mask-image: url('../img/icons/copy.svg');
			}

			&_stats {
				mask-image: url('../img/icons/stat.svg');
			}

			&_delete {
				mask-image: url('../img/icons/delete.svg');
			}

		}

		&_create {
			display: flex;
			height: 238px;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			line-height: 21px;
			color: var(--mave-foreground-primary);
			border: 1px dashed var(--mave-foreground-primary);
		}

		.my-app__action + .my-app__action {
			margin-left: 24px;
		}
	}
}
