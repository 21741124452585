.rcs-custom-scroll {
	min-width: 0;
	min-height: 0;
}

.rcs-custom-scroll .rcs-outer-container {
	overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
	position: relative;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
	opacity: 1;
	transition-duration: .2s;
}

.rcs-custom-scroll .rcs-inner-container {
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.rcs-custom-scroll .rcs-inner-container:after {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 0;
	pointer-events: none;
	content: '';
	background-image: linear-gradient(to bottom, rgba(0,0,0,.2) 0%, rgba(0,0,0,.05) 60%, rgba(0,0,0,0) 100%);
	transition: height .1s ease-in;
	will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
	height: 5px;
	transition: height .15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
	user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
	position: absolute;
	z-index: 2;
	right: 5px;
	padding: 6px 0;
	box-sizing: border-box;
	width: 5px;
	height: 100%;
	opacity: 0;
	pointer-events: none;
	transition: opacity .4s ease-out;
	will-change: opacity;
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
	right: auto;
	left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
	opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
	position: absolute;
	top: 0;
	width: 100%;
}

.rcs-custom-scroll .rcs-inner-handle {
	margin-top: 6px;
	height: calc(100% - 12px);
	background-color: var(--mave-foreground-secondary);
	border-radius: 10px;
}
