.upload-screenshots {
	display: flex;
	overflow: scroll;

	&__upload, &__screen {
		box-sizing: border-box;
		width: 87px;
		min-width: 87px;
		height: 174px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		border-radius: 10px;
		cursor: pointer;

		& + & {
			margin-left: 12px;
		}
	}

	&__upload {
		background-color: var(--mave-background-secondary);
		border: .5px solid var(--mave-accent-primary);

		&:hover {
			background-color: var(--mave-background-secondary-hover);
		}

		&-inside {
			width: 100%;
			height: 100%;
			background-color: var(--mave-accent-primary);
			mask-image: url('../img/icons/camera.svg');
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 28px;
		}
	}

	&__screen {
		position: relative;
		border: .5px solid var(--mave-border);
		cursor: default;

		&:hover {

			.upload-screenshots__screen-close-icon {
				display: block;
			}
		}

		&-close-icon {
			position: absolute;
			top: 4px;
			right: 4px;
			display: none;
			width: 16px;
			height: 16px;
			background-image: url('../img/icons/close-square-normal.svg');
			cursor: pointer;

			&:hover {
				background-image: url('../img/icons/close-square-hover.svg');
			}
		}
	}
}
